.circle-timer {
  position: relative;

  svg {
    width: 50px;
    height: 50px;

    circle {
      stroke: map-get($map: $colors, $key: secondary);
      transform-origin: 50% 50%;
      transform: rotate(-90deg) scaleY(-1);
    }
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    height: 25px;
    font-size: 1.25rem;
    font-weight: 500;
  }
}
