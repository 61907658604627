@each $color, $value in $colors {
  .background-#{$color} {
    background-color: $value;
  }

  .color-#{$color} {
    color: $value;

    & > path,
    svg > path {
      fill: $value;
      stroke: $value;
    }
  }
}
