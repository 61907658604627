.link-button {
  cursor: pointer;
  display: flex;
  gap: 7px;

  & > p {
    font-size: 1.5rem;
    text-decoration: underline;
    text-underline-offset: 5px;
    font-weight: 500;
  }

  & > svg {
    transform: translateY(10px);
  }

  &--back {
    @extend .link-button;

    & > svg {
      transform: rotate(180deg) translateY(-10px);
    }
  }
}
