.quiz-option {
  display: flex;
  gap: 15px;

  width: 100%;
  padding: 5%;

  border: none;
  background-color: map-get($map: $colors, $key: "blue-light");

  cursor: pointer;

  p {
    font-size: 1rem;
  }

  &--correct {
    @extend .quiz-option;
    color: map-get($map: $colors, $key: secondary);
    background-color: map-get($map: $colors, $key: "green");

    svg > path {
      stroke: map-get($map: $colors, $key: secondary);
    }
  }

  &--incorrect {
    @extend .quiz-option;
    @extend .color-secondary;
    background-color: map-get($map: $colors, $key: "red");
  }

  &--missed {
    @extend .quiz-option;

    svg > path {
      stroke: map-get($map: $colors, $key: "green");
    }
  }

  &--timed-out {
    @extend .quiz-option;

    &::before {
      content: '';
      display: inline-block;
      width: 22px;
    }
  }

  > svg.invisible {
    visibility: hidden;
  }
}
