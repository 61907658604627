* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.25rem;
  font-weight: 300;
}

p {
  font-size: 1rem;
}

a {
  text-underline-offset: 3px;
}


#root {
  background-color:map-get($map: $colors, $key: primary);
}
