#quiz-page {
  #banner {
    color: map-get($map: $colors, $key: secondary);
    background-color: map-get($map: $colors, $key: primary);
    padding: 5% 4% 3% 4%;
    display: flex;
    justify-content: space-between;

    & > p {
      margin-top: auto;
      font-size: 1.25rem;
      font-weight: 300;

      span {
        font-weight: 700;
      }
    }
  }

  & > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  & > .container {
    padding: 30px;
    padding-bottom: 50px;

    h3 {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 20px;
    }

    #options {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding-bottom: 60px;
  }

  .header > svg {
    width: 100%;
    height: 100%;
    max-height: 150px;
  }
}
