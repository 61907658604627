#results-page {
  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    align-items: center;
    gap: 40px;

    & > h2 {
      text-align: center;
      font-size: 2.8125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem; /* 106.667% */
      letter-spacing: -0.05938rem;
    }

    & > #result {
      width: 100%;
      text-align: center;

      h1 {
        font-size: 6.25rem;
        font-style: normal;
        font-weight: 800;
        line-height: 6.25rem; /* 100% */
        letter-spacing: -0.1rem;
      }
    }

    & > #links {
      .link-button {
        justify-content: center;
      }

      & > .link-button:last-child {
        margin-top: 50px;

        p {
          font-size: 1rem;
        }

        & > svg {
          scale: 0.8;
          transform: translateY(7px);
        }
      }
    }
  }
}
