#loading-page h2 {
  position: fixed;
  top: 50%;
  margin-top: -50px;
  height: 100px;
  line-height: 100px;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
