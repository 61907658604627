#register-page {
  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    gap: 40px;

    & > #form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input[type='text'],
      input[type='email'] {
        padding: 5%;
        background-color: map-get($map: $colors, $key: primary);
        border: 1px solid map-get($map: $colors, $key: secondary);
        font-size: 1rem;
        color: map-get($map: $colors, $key: secondary);
        font-weight: 300;
        border-radius: 3px;

        &::placeholder {
          color: map-get($map: $colors, $key: secondary);
          opacity: 0.7;
        }

        &.error {
          border-color: map-get($map: $colors, $key: red);
        }
      }

      & > p {
        font-size: 0.85rem;
        margin: 20px 10px 20px 0;
        font-weight: 300;
      }

      & > p.error {
        margin-top: 0;
        color: map-get($map: $colors, $key: 'red');
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;

        &.checkbox {
          label {
            flex: 1;
          }
          input[type='checkbox'] {
            cursor: pointer;
            appearance: none;
            border: 2px solid map-get($map: $colors, $key: secondary);
            height: 28px;
            width: 28px;
            border-radius: 3px;

            &:checked {
              background-color: map-get($map: $colors, $key: secondary);
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 28 28"><rect width="26" height="26" x="1" y="1" rx="1"/><path stroke="%23060932" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M22 9 11 20l-5-5"/></svg>');
            }
          }
        }
      }
    }
  }
}
