#tutorial-page {
  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    gap: 60px;

    & > #text {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }
}
