.header {
  & > svg {
    width: 100%;
    height: 100%;
    max-height: 150px;
  }

  &.iod {
    background-color: #06618e;
  }

  &.now {
    background-color: #fff;
  }

  &.nzoc {
    background-color: #000;
  }

  &.mercury {
    background-color: #fff100;
  }

  &.bk {
    background-color: #f5ebdc;
  }

  &.consumer {
    background-color: #f9f9f9;
  }

  &.tsb {
    background-color: #1a124d;
  }

  &.cmt {
    height: 154px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 0%;
    }
  }

  &.fullers {
    background: linear-gradient(to bottom, #00BBB4 0%, #284F7F 100%);
  }
}
