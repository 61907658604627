#home-page {
  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    gap: 40px;

    #text {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    #links {
      display: flex;
      flex-direction: column;
      gap: 1.5em;
    }

    .text-sm  {
      > p {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem; /* 133.333% */
        letter-spacing: -0.02188rem;
      }
      > svg {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}
