@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

$font-family: "Inter", sans-serif;

$colors: (
  "primary": #060932,
  "secondary": #f7f8ff,

  "green": #0aa302,
  "green-light": #b3ffbf,

  "blue": #3d4ffa,
  "blue-light": #b3e4ff,

  "red": #eb0000
);

$min-mobile-width: 500px;
$min-tablet-width: 836px;
