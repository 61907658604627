#leaderboard-page {
  & > .container {
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2 {
      margin-top: 30px;
      font-size: 2.5rem;
    }

    & > #leaderboard {
      background-color: #f7f8ff1a;
      width: 100%;

      & > div {
        border: 1px solid map-get($map: $colors, $key: primary);
        display: flex;
        justify-content: space-between;

        & > p {
          border: solid map-get($map: $colors, $key: primary);
          border-width: 0 0.5px;
          text-align: center;

          padding: 4%;
          @media (min-width: $min-mobile-width) {
            padding: 3%;
          }
        }

        & > p:nth-child(1) {
          width: 18%;
        }

        & > p:nth-child(2) {
          width: 64%;
          text-align: left;
        }

        & > p:nth-child(3) {
          width: 18%;
        }
      }

      & > #user-submission {
        border-width: 10px 0 0 0;
      }
    }

    & > p {
      font-weight: 300;
      font-size: 0.85rem;

      @media (min-width: $min-mobile-width) {
        margin-bottom: 10%;
      }
    }
  }
}
